:root {
  --white_clr: #fff;
  --orange_clr: #9a6305;
  --orange_clr_two: #9a680e;
  --orange_clr_three: #bb7602;
  --orange_clr_four: #de9416;
  --orange_clr_five: #a26702;
  --orange_clr_six: #aa770b;
  --orange_Clr_seven: #ae7c27;
  --orane_clr_eight: #8c5905;
  --gray_clr: #717171;
  --dark_gray: #232323;
  --light_gray_clr: #6c6c6c;
}

.jsontop {
  top: 0%;
}
.trackimg {
  width: 72px;
  height: 72px;
  object-fit: contain;
  margin-top: -30px;
}
.darkpayborder {
  background: url("./Assets/homepage/darkPay.svg") no-repeat !important;
  background-size: 100% 100% !important;
  padding: 30px 20px;
  min-height: 400px;
  height: 400px;
  max-height: 400px;
}

/* start of common css */
.mt_4p5 {
  margin-top: 2.5rem;
}
.container_90 {
  max-width: 90% !important;
}
.obj_fit_cvr {
  min-width: 100%;
  width: 100%;
  max-width: 100%;
  min-height: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
}
/* end of common css */

/* start of button css */
.hm_cpy_btn {
  border: none;
  outline: none;
  background: url("./Assets/homepage/cpybtn.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: var(--white_clr);
  font-size: 11px;
  width: 135px;
  font-weight: 500;
  height: 33px;
  transition: 0.3s;
}
.hm_cpy_btn:hover {
  /* scale: 99%; */
  color: #dc9823;
}
.hm_submit_btn {
  background: url("./Assets/homepage/submit.svg");
  border: none;
  outline: none;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: var(--white_clr);
  font-size: 13px;
  width: 150px;
  font-weight: 500;
  height: 38px;
  transition: 0.3s;
  margin-top: 35px;
}
.hm_submit_btn:hover {
  /* scale: 99%; */
  filter: drop-shadow(2px -2px 14px #9b6204a3);
}
.ad_cont_btn {
  background: url("./Assets/airdrop/continue.png");
  border: none;
  outline: none;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: var(--white_clr);
  font-size: 13px;
  width: 100%;
  font-weight: 500;
  height: 30px;
  transition: 0.3s;
}
.ad_connect_btn {
  background: url("./Assets/airdrop/connect.png");
  border: none;
  outline: none;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: var(--white_clr);
  font-size: 13px;
  width: 100%;
  font-weight: 500;
  height: 30px;
  transition: 0.3s;
}
.ad_connect_btn:hover {
  color: var(--orane_clr_eight);
}
.ad_wlt_border {
}
/* end of button css */

/* start of homepage */

/* start of contract address */
.hm_contract {
  color: var(--white_clr);
}
.hm_lnr_grd_txt {
  background: linear-gradient(to bottom, #986105, 90%, #000);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  font-weight: 900;
  letter-spacing: 2px;
  font-size: 40px;
}
.hm_contract_banner {
  background: url("./Assets/homepage/BannerGradient.svg");
}
.h3_fw_300 {
  margin-top: -10px;
  font-weight: 300;
  font-size: 26px;
}
.hm_cntct_id {
  font-weight: 300;
  word-break: break-all;
  font-size: 14px;
}
.hm_hds_wht {
  font-size: 25px;
  font-weight: 600;
  letter-spacing: 1.5px;
}
.hm_inpt_wrp {
  width: 240px;
  border: 2px solid var(--orange_clr);
  border-radius: 5px;
  height: 50px;
  margin-top: 1.5rem;
}
.hm_inpt_wrp input {
  background: transparent;
  border: none;
  outline: none;
  height: 100%;
  color: var(--gray_clr);
  font-weight: 600;
  width: 100% !important;
}
.hm_inpt_wrp input::placeholder {
  color: var(--gray_clr);
  font-weight: 600;
}
.hm_inpt_wrp svg {
  cursor: pointer;
}
/* end of contract address */

/* start of our blog */
.hm_ourBlog {
  color: var(--white_clr);
}

.hm_blg_crd_wrp {
  background: url("./Assets/homepage/ourblogcard.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right 0px top 0px;
  padding: 10px 10px !important;
  min-height: fit-content;
  height: fit-content;
  max-height: fit-content;
}
.hm_blg_crd_img_wrp {
  min-width: 100%;
  width: 100%;
  max-width: 100%;
  min-height: 155px;
  height: 155px;
  max-height: 155px;
  overflow: hidden;
}
.hm_blg_date {
  color: var(--orange_clr_two);
  font-weight: 600;
  font-size: 13px;
}
.hm_blg_tle {
  font-weight: 600;
  font-size: 16px;
}
.hm_blg_sub_cnt {
  font-weight: 200;
  font-size: 13px;
  max-width: 95%;
}
.hm_blg_col_dvdr_cnt {
  margin-top: 10px;
}
.hm_blg_col_dvdr {
  margin-bottom: 35px;
}

/* end of our blog */

/* start of dark universe exchange */
.hm_due {
  color: var(--white_clr);
}
.hm_subtle_org {
  color: var(--orange_clr_three);
  font-size: 14px;
}
.hm_dls_wht {
  color: var(--white_clr);
  font-size: 14px;
  line-height: 2;
}
.hm_due_lot svg {
  width: 70% !important;
}
.hm_due_crd {
  background: url("./Assets/homepage/due_card.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  min-width: 290px;
  width: 290px;
  max-width: 290px;
  min-height: 420px;
  height: 420px;
  max-height: 420px;
  padding: 60px 20px 10px 20px;
}
.hm_due_crd_cnt {
  font-size: 14px;
  font-weight: 300;
}
.hm_due_crd_img {
  width: 50px;
}

/* end of dark universe exchange */

/* start of dark pay */

.hm_drkpay_lot svg {
  margin-left: 20px !important;
}
.hm_drkpay_crd {
  background: url("./Assets/homepage/darkPay.svg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  min-width: 295px;
  width: 295px;
  max-width: 295px;
  min-height: 408px;
  height: 408px;
  max-height: 408px;
  padding-left: 20px;
  padding-right: 20px;
}
.hm_drkpay_crd_img {
  width: 50px;
  position: relative;
  top: 8px;
}

/* end of dark pay */

/* start of air drop */

.ad_inner {
  color: var(--white_clr);
}

.ad_ban_tle {
  font-weight: 700;
  letter-spacing: 1px;
  font-size: 33px;
}
.ad_ban_subtle {
}
.ad_ban_subtle span {
  color: var(--orange_Clr_seven);
  font-weight: 800;
}
.ad_data_dls {
  background: url("./Assets/airdrop/dataVertical.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  min-height: fit-content;
  height: fit-content;
  padding: 10px 30px;
}
.ad_data_col {
  border-bottom: 1px solid var(--orange_clr_four);
}
.ad_data_wht_p {
  font-weight: 500;
}
.ad-data_orng_p {
  color: var(--orange_clr_five);
  font-weight: 500;
}

.ad_form_crd {
  background: url("./Assets/airdrop/form.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  min-height: fit-content;
  height: fit-content;
  padding: 45px 25px;
}
.ad_form_crd h1 {
  font-weight: 600;
}
.ad_form_content {
  font-size: 14px;
}

/* start of react select */
.cust_react_slt {
  height: 45px;
}
.cust_react_slt .css-1u9des2-indicatorSeparator {
  display: none;
}
.slt_tri_one {
  width: 0px;
  border-right: 15px solid var(--dark_gray);
  border-top: 15px solid #0c0c0d;
  top: 0;
  left: 0;
  z-index: 1;
}
.slt_tri_two {
  width: 0px;
  border-right: 15px solid #0c0c0d;
  border-top: 15px solid var(--dark_gray);
  bottom: 0;
  right: 0;
  z-index: 1;
}
.rct_slt_icn {
  z-index: 1;
  top: 50%;
  transform: translate(-50%, -50%);
  right: 1%;
}
.cust_react_slt .css-amq6nb-option:active,
.cust_react_slt .css-ygc6os-option:active,
.cust_react_slt .css-9l5fek-option:active {
  background-color: var(--orange_clr_five);
}
.cust_react_slt .css-1nmdiq5-menu {
  z-index: 2;
}
.cust_react_slt .css-1nmdiq5-menu {
  border-radius: 0px !important;
}
/* .cust_react_slt .css-1xc3v61-indicatorContainer {
  display: none;
} */
/* end of react select */
.ad_form_inpt {
  height: 45px;
  background-color: #232323;
}

.ad_form_inpt input {
  background: transparent;
  border: none;
  outline: none;
  width: 100%;
  height: 100%;
}
.ad_wlt_address {
  height: 45px;
  width: 100%;
  background-color: var(--dark_gray);
  padding-left: 20px;
  padding-right: 20px;
}
.ad_wlt_address input {
  width: 100%;
  height: 100%;
  outline: none;
  border: none;
  background-color: transparent;
  color: var(--white_clr);
  padding: 0;
}
.ad_wlt_address input::placeholder {
  color: var(--white_clr);
}
.ad_wlt_address button {
  background-color: transparent;
  outline: none;
  border: none;
  border: none;
  color: var(--orange_clr_six);
}
.ad_wlt_border {
  height: 1px;
  background: var(--light_gray_clr);
}
.ad_or_border {
  font-size: 13px;
}
/* .ad_or_border::before {
  content: "";
  width: 43%;
  height: 1px;
  background-color: var(--light_gray_clr);
  position: absolute;
  right: 54%;
}
.ad_or_border::after {
  content: "";
  width: 43%;
  height: 1px;
  background-color: var(--light_gray_clr);
  position: absolute;
  left: 54%;
} */
.ad_or_border::before {
  content: "";
  width: 40%;
  height: 1px;
  background-color: var(--light_gray_clr);
  position: absolute;
  right: 58%;
}
.ad_or_border::after {
  content: "";
  width: 40%;
  height: 1px;
  background-color: var(--light_gray_clr);
  position: absolute;
  left: 58%;
}

.ad_faq_row {
  margin-top: 75px;
}
.ad_faq_ind_one {
  width: fit-content;
  top: -13px;
  left: -16px;
}
.ad_faq_ind_one img {
  width: 53px;
}
.ad_faq_ind_two {
  bottom: -6px;
  right: -5px;
  width: fit-content;
  padding: 0px;
}
.ad_faq_ind_two img {
  width: 53px;
}

.ad_faq_card_one {
  background: url("./Assets/airdrop/faqCardOne.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 20px 20px;
  min-height: 290px;
  height: 290px;
  cursor: pointer;
  transition: 0.3s;
}
.ad_faq_card_one:hover {
  background: url("./Assets/airdrop/activeFaqCard.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 20px 20px;
  min-height: 290px;
  height: 290px;
  cursor: pointer;
}
.ad_faq_card_two {
  background: url("./Assets/airdrop/faqcardtwo.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 20px 20px;
  min-height: 290px;
  height: 290px;
  cursor: pointer;
}
.ad_faq_card_two:hover {
  background: url("./Assets/airdrop/activefaqcardtwo.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 20px 20px;
  min-height: 290px;
  height: 290px;
  cursor: pointer;
}
.ad_faq_card_three {
  background-image: url("./Assets/airdrop/faqcardthree.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 20px 20px;
  min-height: 290px;
  height: 290px;
  cursor: pointer;
}
.ad_faq_card_three:hover {
  background-image: url("./Assets/airdrop/activefaqcardthree.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 20px 20px;
  min-height: 290px;
  height: 290px;
  cursor: pointer;
}
.ad_faq_card_four {
  background: url("./Assets/airdrop/faqcardfour.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 20px 20px;
  min-height: 290px;
  height: 290px;
  cursor: pointer;
}
.ad_faq_card_four:hover {
  background: url("./Assets/airdrop/activefaqcardfour.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 20px 20px;
  min-height: 290px;
  height: 290px;
  cursor: pointer;
}
.ad_faq_card_five {
  background: url("./Assets/airdrop/faqcardfive.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 20px 20px;
  min-height: 290px;
  height: 290px;
  cursor: pointer;
}
.ad_faq_card_five:hover {
  background: url("./Assets/airdrop/activefaqcardfive.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 20px 20px;
  min-height: 290px;
  height: 290px;
  cursor: pointer;
}
.ad_faq_card_six {
  background: url("./Assets/airdrop/faqcardsix.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 20px 20px;
  min-height: 290px;
  height: 290px;
  cursor: pointer;
}
.ad_faq_card_six:hover {
  background: url("./Assets/airdrop/activefaqcardsix.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 20px 20px;
  min-height: 290px;
  height: 290px;
  cursor: pointer;
}

.ad_faq_subtle {
  font-size: 14px;
  font-weight: 200;
}
.ad_strs_json {
  top: 0;
  left: 0;
  width: 50%;
}
.ad_strs_json2 {
  bottom: 0;
  right: 0;
  width: 50%;
}

/* end of air drop */

.header_btn_new a {
  color: white !important;
}

/* start of modal */
.modal-backdrop.show {
  opacity: 0.9;
}

.mdl_cnt_wlt .modal-content {
  background: black !important;
  border-radius: 0px;
  border: 3px solid #0c0c0d;
  position: relative;
}
.mdl_head_h1 {
  color: var(--orange_clr_four);
}
.mdl_cls_icn {
  position: absolute;
  color: var(--orange_clr_four);
  top: 18px;
  right: 10px;
  font-size: 23px;
  cursor: pointer;
}
.mta_msk_img {
  width: 40px;
}
.cnt_wlt_img {
  width: 55px;
}
.mdl_cnt_wlt {
  color: var(--white_clr);
}
/* end of modal */

/* media */

@media (min-width: 360px) {
  .ad_faq_card_one {
    min-height: 240px;
    height: 240px;
  }
  .ad_faq_card_one:hover {
    min-height: 240px;
    height: 240px;
  }
  .ad_faq_card_two {
    min-height: 240px;
    height: 240px;
  }
  .ad_faq_card_two:hover {
    min-height: 240px;
    height: 240px;
  }
  .ad_faq_card_three {
    min-height: 240px;
    height: 240px;
  }
  .ad_faq_card_three:hover {
    min-height: 240px;
    height: 240px;
  }
  .ad_faq_card_four {
    min-height: 240px;
    height: 240px;
  }
  .ad_faq_card_four:hover {
    min-height: 240px;
    height: 240px;
  }
  .ad_faq_card_five {
    min-height: 240px;
    height: 240px;
  }
  .ad_faq_card_five:hover {
    min-height: 240px;
    height: 240px;
  }
  .ad_faq_card_six {
    min-height: 240px;
    height: 240px;
  }
  .ad_faq_card_six:hover {
    min-height: 240px;
    height: 240px;
  }
}

@media (min-width: 410px) {
  .ad_faq_card_one {
    min-height: 240px;
    height: 240px;
  }
  .ad_faq_card_one:hover {
    min-height: 240px;
    height: 240px;
  }
  .ad_faq_card_two {
    min-height: 240px;
    height: 240px;
  }
  .ad_faq_card_two:hover {
    min-height: 240px;
    height: 240px;
  }
  .ad_faq_card_three {
    min-height: 240px;
    height: 240px;
  }
  .ad_faq_card_three:hover {
    min-height: 240px;
    height: 240px;
  }
  .ad_faq_card_four {
    min-height: 240px;
    height: 240px;
  }
  .ad_faq_card_four:hover {
    min-height: 240px;
    height: 240px;
  }
  .ad_faq_card_five {
    min-height: 240px;
    height: 240px;
  }
  .ad_faq_card_five:hover {
    min-height: 240px;
    height: 240px;
  }
  .ad_faq_card_six {
    min-height: 240px;
    height: 240px;
  }
  .ad_faq_card_six:hover {
    min-height: 240px;
    height: 240px;
  }
}

@media (min-width: 576px) {
  /* start of button css */
  .hm_cpy_btn {
    font-size: 12px;
    width: 140px;
    height: 35px;
  }
  /* end of button css */
  .hm_cntct_id {
    font-size: 17px;
  }
  .hm_hds_wht {
    font-size: 30px;
  }
  .hm_inpt_wrp {
    width: 270px;
  }
  .hm_submit_btn {
    width: 160px;
    height: 40px;
    margin-top: 40px;
  }

  /* start of blog */

  .hm_blg_crd_img_wrp {
    min-width: 100%;
    width: 100%;
    max-width: 100%;
    min-height: 230px;
    height: 230px;
    max-height: 230px;
    overflow: hidden;
  }
  .hm_blg_date {
    font-size: 15px;
  }
  .hm_blg_tle {
    font-size: 18px;
  }
  .hm_blg_sub_cnt {
    font-size: 14px;
    max-width: 95%;
  }
  .hm_blg_col_dvdr_cnt {
    margin-top: 10px;
  }
  .hm_blg_col_dvdr {
    margin-bottom: 35px;
  }
  /* end of blog */

  /* start of dark universe exchange */

  .hm_subtle_org {
    font-size: 15px;
  }
  .hm_dls_wht {
    font-size: 15px;
  }
  .hm_due_crd {
    background: url("./Assets/homepage/due_card.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    min-width: 350px;
    width: 350px;
    max-width: 350px;
    min-height: 450px;
    height: 450px;
    max-height: 450px;
    padding: 60px 30px 10px 30px;
  }
  .hm_due_crd_cnt {
    font-size: 16px;
  }
  /* end of dark universe exchange */

  /* start of dark pay */

  .hm_drkpay_lot svg {
    margin-left: 34px !important;
  }
  .hm_drkpay_crd {
    min-width: 380px;
    width: 380px;
    max-width: 380px;
    min-height: 440px;
    height: 440px;
    max-height: 440px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .hm_drkpay_crd_img {
    width: 67px;
    position: relative;
    top: -35px;
  }
  /* end of dark pay */

  /* start of air drop */

  .ad_data_dls {
    background: url("./Assets/airdrop/data.png");
    padding: 10px 10px;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .ad_data_col {
    border-right: 1px solid var(--orange_clr_four);
    border-bottom: unset;
  }
  .ad_form_crd {
    padding: 60px 40px;
  }
  .ad_form_content {
    font-size: 15px;
  }
  .ad_cont_btn {
    height: 45px;
  }
  .ad_connect_btn {
    height: 45px;
  }
  .ad_faq_card_one {
    min-height: 330px;
    height: 330px;
  }
  .ad_faq_card_one:hover {
    min-height: 330px;
    height: 330px;
  }
  .ad_faq_card_two {
    min-height: 330px;
    height: 330px;
  }
  .ad_faq_card_two:hover {
    min-height: 330px;
    height: 330px;
  }
  .ad_faq_card_three {
    min-height: 330px;
    height: 330px;
  }
  .ad_faq_card_three:hover {
    min-height: 330px;
    height: 330px;
  }
  .ad_faq_card_four {
    min-height: 330px;
    height: 330px;
  }
  .ad_faq_card_four:hover {
    min-height: 330px;
    height: 330px;
  }
  .ad_faq_card_five {
    min-height: 330px;
    height: 330px;
  }
  .ad_faq_card_five:hover {
    min-height: 330px;
    height: 330px;
  }
  .ad_faq_card_six {
    min-height: 330px;
    height: 330px;
  }
  .ad_faq_card_six:hover {
    min-height: 330px;
    height: 330px;
  }
  .ad_faq_row {
    margin-top: 90px;
  }
  .ad_faq_ind_one {
    top: -14px;
    left: -19px;
  }
  .ad_faq_ind_one img {
    width: 57px;
  }
  .ad_faq_ind_two {
    bottom: -8px;
    right: -8px;
  }
  .ad_faq_ind_two img {
    width: 57px;
  }
  /* end of air drop */
  .mdl_head_h1 {
    font-size: 25px;
  }

  .mdl_cls_icn {
    position: absolute;
    color: var(--orange_clr_four);
    top: 40px;
    right: 30px;
    font-size: 30px;
    cursor: pointer;
  }
  .mta_msk_img {
    width: 50px;
  }
  .cnt_wlt_img {
    width: 65px;
  }
  .mdl_cnt_wlt {
    color: var(--white_clr);
  }
  .mdl_cnt_wlt .modal-body {
    padding: 40px 30px;
  }
}
@media (min-width: 680px) {
  .ad_faq_card_one {
    min-height: 280px;
    height: 280px;
  }
  .ad_faq_card_one:hover {
    min-height: 280px;
    height: 280px;
  }
  .ad_faq_card_two {
    min-height: 280px;
    height: 280px;
  }
  .ad_faq_card_two:hover {
    min-height: 280px;
    height: 280px;
  }
  .ad_faq_card_three {
    min-height: 280px;
    height: 280px;
  }
  .ad_faq_card_three:hover {
    min-height: 280px;
    height: 280px;
  }
  .ad_faq_card_four {
    min-height: 280px;
    height: 280px;
  }
  .ad_faq_card_four:hover {
    min-height: 280px;
    height: 280px;
  }
  .ad_faq_card_five {
    min-height: 280px;
    height: 280px;
  }
  .ad_faq_card_five:hover {
    min-height: 280px;
    height: 280px;
  }
  .ad_faq_card_six {
    min-height: 280px;
    height: 280px;
  }
  .ad_faq_card_six:hover {
    min-height: 280px;
    height: 280px;
  }
}
@media (min-width: 768px) {
  .hm_inpt_wrp {
    width: 280px;
  }

  /* start of blog */

  .hm_blg_crd_img_wrp {
    min-width: 100%;
    width: 100%;
    max-width: 100%;
    min-height: 100%;
    height: 100%;
    max-height: 100%;
    overflow: hidden;
  }
  .hm_blg_date {
    font-size: 15px;
  }
  .hm_blg_tle {
    font-size: 22px;
  }
  .hm_blg_sub_cnt {
    font-size: 18px;
    max-width: 95%;
  }
  .hm_blg_col_dvdr_cnt {
    margin-top: 10px;
  }
  .hm_blg_col_dvdr {
    margin-bottom: 35px;
  }
  /* end of blog */

  /* start of dark universe exchange */

  .hm_subtle_org {
    font-size: 17px;
  }
  .hm_dls_wht {
    font-size: 17px;
  }

  /* end of dark universe exchange */

  /* start of dark pay */

  .hm_drkpay_lot svg {
    margin-left: 34px !important;
  }
  .hm_drkpay_crd {
    min-width: 400px;
    width: 400px;
    max-width: 400px;
    min-height: 520px;
    height: 520px;
    max-height: 520px;
  }
  .hm_drkpay_crd_img {
    top: -2px;
  }
  /* end of dark pay */

  /* start of air drop */
  .ad_form_crd {
    padding: 65px 50px;
  }
  .ad_cont_btn {
    font-size: 15px;
  }
  .ad_connect_btn {
    font-size: 15px;
  }
  .ad_faq_subtle {
    font-size: 16px;
  }
  .ad_strs_json {
    top: 0;
    left: -50%;
    width: 100%;
  }
  .ad_faq_ind_one {
    top: -17px;
    left: -19px;
  }
  .ad_faq_ind_one img {
    width: 69px;
  }

  .ad_faq_ind_two {
    bottom: -8px;
    right: -6px;
  }
  .ad_faq_ind_two img {
    width: 57px;
  }
  .ad_faq_card_one {
    min-height: 330px;
    height: 330px;
  }
  .ad_faq_card_one:hover {
    min-height: 330px;
    height: 330px;
  }
  .ad_faq_card_two {
    min-height: 330px;
    height: 330px;
  }
  .ad_faq_card_two:hover {
    min-height: 330px;
    height: 330px;
  }
  .ad_faq_card_three {
    min-height: 330px;
    height: 330px;
  }
  .ad_faq_card_three:hover {
    min-height: 330px;
    height: 330px;
  }
  .ad_faq_card_four {
    min-height: 330px;
    height: 330px;
  }
  .ad_faq_card_four:hover {
    min-height: 330px;
    height: 330px;
  }
  .ad_faq_card_five {
    min-height: 330px;
    height: 330px;
  }
  .ad_faq_card_five:hover {
    min-height: 330px;
    height: 330px;
  }
  .ad_faq_card_six {
    min-height: 330px;
    height: 330px;
  }
  .ad_faq_card_six:hover {
    min-height: 330px;
    height: 330px;
  }
  /* end of air drop */
}
@media (min-width: 850px) {
  .ad_faq_card_one {
    min-height: 280px;
    height: 280px;
  }
  .ad_faq_card_one:hover {
    min-height: 280px;
    height: 280px;
  }
  .ad_faq_card_two {
    min-height: 280px;
    height: 280px;
  }
  .ad_faq_card_two:hover {
    min-height: 280px;
    height: 280px;
  }
  .ad_faq_card_three {
    min-height: 280px;
    height: 280px;
  }
  .ad_faq_card_three:hover {
    min-height: 280px;
    height: 280px;
  }
  .ad_faq_card_four {
    min-height: 280px;
    height: 280px;
  }
  .ad_faq_card_four:hover {
    min-height: 280px;
    height: 280px;
  }
  .ad_faq_card_five {
    min-height: 280px;
    height: 280px;
  }
  .ad_faq_card_five:hover {
    min-height: 280px;
    height: 280px;
  }
  .ad_faq_card_six {
    min-height: 280px;
    height: 280px;
  }
  .ad_faq_card_six:hover {
    min-height: 280px;
    height: 280px;
  }
}
@media (min-width: 992px) {
  .hm_lnr_grd_txt {
    font-size: 55px;
  }
  .h3_fw_300 {
    font-size: 37px;
  }
  .hm_cntct_id {
    font-size: 18px;
  }
  .hm_hds_wht {
    font-size: 35px;
  }
  .hm_submit_btn {
    font-size: 15px;
  }

  /* start of blog */

  .hm_blg_crd_img_wrp {
    min-width: 100%;
    width: 100%;
    max-width: 100%;
    min-height: 100%;
    height: 100%;
    max-height: 100%;
    overflow: hidden;
  }
  .hm_blg_date {
    font-size: 15px;
  }
  .hm_blg_tle {
    font-size: 22px;
  }
  .hm_blg_sub_cnt {
    font-size: 18px;
    max-width: 95%;
  }
  .hm_blg_col_dvdr_cnt {
    margin-top: 10px;
  }
  .hm_blg_col_dvdr {
    margin-bottom: 35px;
  }
  /* end of blog */
  /* start of air drop */
  .ad_form_crd {
    padding: 65px 55px;
  }
  .ad_faq_subtle {
    font-size: 16px;
  }
  .ad_faq_card_one {
    min-height: 300px;
    height: 300px;
  }
  .ad_faq_card_one:hover {
    min-height: 300px;
    height: 300px;
  }
  .ad_faq_card_two {
    min-height: 300px;
    height: 300px;
  }
  .ad_faq_card_two:hover {
    min-height: 300px;
    height: 300px;
  }
  .ad_faq_card_three {
    min-height: 300px;
    height: 300px;
  }
  .ad_faq_card_three:hover {
    min-height: 300px;
    height: 300px;
  }
  .ad_faq_card_four {
    min-height: 300px;
    height: 300px;
  }
  .ad_faq_card_four:hover {
    min-height: 300px;
    height: 300px;
  }
  .ad_faq_card_five {
    min-height: 300px;
    height: 300px;
  }
  .ad_faq_card_five:hover {
    min-height: 300px;
    height: 300px;
  }
  .ad_faq_card_six {
    min-height: 300px;
    height: 300px;
  }
  .ad_faq_card_six:hover {
    min-height: 300px;
    height: 300px;
  }
  .ad_faq_row {
    margin-top: 100px;
  }
  .ad_faq_ind_one {
    top: -16px;
    left: -17px;
  }
  .ad_faq_ind_one img {
    width: 65px;
  }
  .ad_faq_ind_two {
    bottom: -10px;
    right: -7px;
  }
  .ad_faq_ind_two img {
    width: 65px;
  }
  /* end of air drop */
}
@media (min-width: 1100px) {
  .ad_faq_card_one {
    min-height: 280px;
    height: 280px;
  }
  .ad_faq_card_one:hover {
    min-height: 280px;
    height: 280px;
  }
  .ad_faq_card_two {
    min-height: 280px;
    height: 280px;
  }
  .ad_faq_card_two:hover {
    min-height: 280px;
    height: 280px;
  }
  .ad_faq_card_three {
    min-height: 280px;
    height: 280px;
  }
  .ad_faq_card_three:hover {
    min-height: 280px;
    height: 280px;
  }
  .ad_faq_card_four {
    min-height: 280px;
    height: 280px;
  }
  .ad_faq_card_four:hover {
    min-height: 280px;
    height: 280px;
  }
  .ad_faq_card_five {
    min-height: 280px;
    height: 280px;
  }
  .ad_faq_card_five:hover {
    min-height: 280px;
    height: 280px;
  }
  .ad_faq_card_six {
    min-height: 280px;
    height: 280px;
  }
  .ad_faq_card_six:hover {
    min-height: 280px;
    height: 280px;
  }
}

@media (min-width: 1200px) {
  /* start of button css */
  .hm_cpy_btn {
    font-size: 13px;
    width: 180px;
    height: 48px;
  }
  /* end of button css */
  .hm_100vh {
    height: 100vh;
    min-height: 100vh;
  }
  .hm_lnr_grd_txt {
    font-size: 60px;
  }
  .h3_fw_300 {
    font-size: 41px;
  }
  .hm_cntct_id {
    font-size: 18px;
  }
  .hm_hds_wht {
    font-size: 38px;
  }
  .hm_inpt_wrp {
    width: 310px;
  }
  .hm_submit_btn {
    font-size: 15px;
    width: 170px;
    height: 42px;
    margin-top: 40px;
  }

  /* start of road map */

  .hm_rdmp .container {
  }
  /* end of road map */

  /* start of dark universe exchange */

  .hm_subtle_org {
    font-size: 18px;
  }
  .hm_dls_wht {
    font-size: 18px;
  }
  /* end of dark universe exchange */

  /* start of dark pay */

  .hm_drkpay_lot svg {
    margin-left: 34px !important;
  }
  .hm_drkpay_crd {
    min-width: 345px;
    width: 345px;
    max-width: 345px;
    min-height: 505px;
    height: 505px;
    max-height: 505px;
  }
  .hm_drkpay_crd_img {
    top: -12px;
  }
  /* end of dark pay */
  /* start of air drop */
  .ad_form_crd {
    padding: 70px 60px;
  }
  .ad_faq_subtle {
    font-size: 15px;
  }
  .ad_faq_card_one {
    min-height: 320px;
    height: 320px;
  }
  .ad_faq_card_one:hover {
    min-height: 320px;
    height: 320px;
  }
  .ad_faq_card_two {
    min-height: 320px;
    height: 320px;
  }
  .ad_faq_card_two:hover {
    min-height: 320px;
    height: 320px;
  }
  .ad_faq_card_three {
    min-height: 320px;
    height: 320px;
  }
  .ad_faq_card_three:hover {
    min-height: 320px;
    height: 320px;
  }
  .ad_faq_card_four {
    min-height: 320px;
    height: 320px;
  }
  .ad_faq_card_four:hover {
    min-height: 320px;
    height: 320px;
  }
  .ad_faq_card_five {
    min-height: 320px;
    height: 320px;
  }
  .ad_faq_card_five:hover {
    min-height: 320px;
    height: 320px;
  }
  .ad_faq_card_six {
    min-height: 320px;
    height: 320px;
  }
  .ad_faq_card_six:hover {
    min-height: 320px;
    height: 320px;
  }
  .ad_ban_tle {
    font-size: 45px;
  }
  .ad_ban_subtle {
    font-size: 18px;
  }
  .ad_faq_row {
    margin-top: 115px;
  }
  .ad_faq_ind_one {
    top: -17px;
    left: -20px;
  }
  .ad_faq_ind_one img {
    width: 67px;
  }
  .ad_faq_ind_two {
    bottom: -11px;
    right: -9px;
  }
  .ad_faq_ind_two img {
    width: 67px;
  }
  /* end of air drop */
}

@media (min-width: 1400px) {
  .hm_lnr_grd_txt {
    font-size: 65px;
  }
  .h3_fw_300 {
    font-size: 45px;
  }
  .hm_cntct_id {
    font-size: 21px;
  }
  .hm_hds_wht {
    font-size: 41px;
  }
  .hm_inpt_wrp {
    margin-top: 2.5rem;
  }
  .hm_submit_btn {
    font-size: 16px;
    width: 175px;
    height: 50px;
    margin-top: 40px;
  }

  /* start of dark universe exchange */

  .hm_subtle_org {
    font-size: 19px;
  }
  .hm_dls_wht {
    font-size: 19px;
  }
  /* end of dark universe exchange */

  /* start of dark pay */

  .hm_drkpay_lot svg {
    margin-left: 34px !important;
  }
  .hm_drkpay_crd {
    min-width: 350px;
    width: 350px;
    max-width: 350px;
    min-height: 500px;
    height: 500px;
    max-height: 500px;
    padding: 30px 20px 10px 20px;
  }
  .hm_drkpay_crd_img {
    top: -13px;
  }
  /* end of dark pay */

  /* start of air drop */
  .ad_data_wht_p,
  .ad-data_orng_p {
    font-size: 18px;
  }
  .ad_form_content {
    font-size: 16px;
  }
  .ad_faq_subtle {
    font-size: 15px;
  }
  .ad_faq_card_one {
    min-height: 360px;
    height: 360px;
  }
  .ad_faq_card_one:hover {
    min-height: 360px;
    height: 360px;
  }
  .ad_faq_card_two {
    min-height: 360px;
    height: 360px;
  }
  .ad_faq_card_two:hover {
    min-height: 360px;
    height: 360px;
  }
  .ad_faq_card_three {
    min-height: 360px;
    height: 360px;
  }
  .ad_faq_card_three:hover {
    min-height: 360px;
    height: 360px;
  }
  .ad_faq_card_four {
    min-height: 360px;
    height: 360px;
  }
  .ad_faq_card_four:hover {
    min-height: 360px;
    height: 360px;
  }
  .ad_faq_card_five {
    min-height: 360px;
    height: 360px;
  }
  .ad_faq_card_five:hover {
    min-height: 360px;
    height: 360px;
  }
  .ad_faq_card_six {
    min-height: 360px;
    height: 360px;
  }
  .ad_faq_card_six:hover {
    min-height: 360px;
    height: 360px;
  }
  .ad_ban_tle {
    font-size: 49px;
  }
  .ad_ban_subtle {
    font-size: 21px;
  }
  .ad_faq_ind_one {
    top: -19px;
    left: -22px;
  }
  .ad_faq_ind_one img {
    width: 70px;
  }
  .ad_faq_ind_two {
    bottom: -12px;
    right: -12px;
  }
  .ad_faq_ind_two img {
    width: 70px;
  }
  /* end of air drop */
}
@media (min-width: 1550px) {
  .ad_faq_card_one {
    min-height: 330px;
    height: 330px;
  }
  .ad_faq_card_one:hover {
    min-height: 330px;
    height: 330px;
  }
  .ad_faq_card_two {
    min-height: 330px;
    height: 330px;
  }
  .ad_faq_card_two:hover {
    min-height: 330px;
    height: 330px;
  }
  .ad_faq_card_three {
    min-height: 330px;
    height: 330px;
  }
  .ad_faq_card_three:hover {
    min-height: 330px;
    height: 330px;
  }
  .ad_faq_card_four {
    min-height: 330px;
    height: 330px;
  }
  .ad_faq_card_four:hover {
    min-height: 330px;
    height: 330px;
  }
  .ad_faq_card_five {
    min-height: 330px;
    height: 330px;
  }
  .ad_faq_card_five:hover {
    min-height: 330px;
    height: 330px;
  }
  .ad_faq_card_six {
    min-height: 330px;
    height: 330px;
  }
  .ad_faq_card_six:hover {
    min-height: 330px;
    height: 330px;
  }
}

@media (min-width: 1600px) {
  .hm_inpt_wrp {
    width: 50%;
    height: 55px;
  }
  .hm_hds_wht {
    font-size: 50px;
  }
  .hm_inpt_wrp {
    width: 375px;
  }
}
@media (min-width: 1650px) {
  .ad_faq_card_one {
    min-height: 320px;
    height: 320px;
  }
  .ad_faq_card_one:hover {
    min-height: 320px;
    height: 320px;
  }
  .ad_faq_card_two {
    min-height: 320px;
    height: 320px;
  }
  .ad_faq_card_two:hover {
    min-height: 320px;
    height: 320px;
  }
  .ad_faq_card_three {
    min-height: 320px;
    height: 320px;
  }
  .ad_faq_card_three:hover {
    min-height: 320px;
    height: 320px;
  }
  .ad_faq_card_four {
    min-height: 320px;
    height: 320px;
  }
  .ad_faq_card_four:hover {
    min-height: 320px;
    height: 320px;
  }
  .ad_faq_card_five {
    min-height: 320px;
    height: 320px;
  }
  .ad_faq_card_five:hover {
    min-height: 320px;
    height: 320px;
  }
  .ad_faq_card_six {
    min-height: 320px;
    height: 320px;
  }
  .ad_faq_card_six:hover {
    min-height: 320px;
    height: 320px;
  }
}
@media (min-width: 1800px) {
  .ad_faq_card_one {
    min-height: 290px;
    height: 290px;
  }
  .ad_faq_card_one:hover {
    min-height: 290px;
    height: 290px;
  }
  .ad_faq_card_two {
    min-height: 290px;
    height: 290px;
  }
  .ad_faq_card_two:hover {
    min-height: 290px;
    height: 290px;
  }
  .ad_faq_card_three {
    min-height: 290px;
    height: 290px;
  }
  .ad_faq_card_three:hover {
    min-height: 290px;
    height: 290px;
  }
  .ad_faq_card_four {
    min-height: 290px;
    height: 290px;
  }
  .ad_faq_card_four:hover {
    min-height: 290px;
    height: 290px;
  }
  .ad_faq_card_five {
    min-height: 290px;
    height: 290px;
  }
  .ad_faq_card_five:hover {
    min-height: 290px;
    height: 290px;
  }
  .ad_faq_card_six {
    min-height: 290px;
    height: 290px;
  }
  .ad_faq_card_six:hover {
    min-height: 290px;
    height: 290px;
  }
}
@media (min-width: 1920px) {
  /* start of button css */
  .hm_cpy_btn {
    font-size: 16px;
    width: 220px;
    height: 54px;
  }
  /* end of button css */
  .hm_lnr_grd_txt {
    font-size: 70px;
  }
  .h3_fw_300 {
    font-size: 48px;
  }
  .hm_cntct_id {
    font-size: 25px;
  }
  .hm_hds_wht {
    font-size: 50px;
  }
  .hm_inpt_wrp {
    width: 385px;
  }
  .hm_submit_btn {
    width: 180px;
    height: 60px;
  }

  /* start of blog */

  .hm_blg_crd_img_wrp {
    min-width: 100%;
    width: 100%;
    max-width: 100%;
    min-height: 100%;
    height: 100%;
    max-height: 100%;
    overflow: hidden;
  }
  .hm_blg_date {
    font-size: 18px;
  }
  .hm_blg_tle {
    font-size: 25px;
  }
  .hm_blg_sub_cnt {
    font-size: 18px;
    max-width: 95%;
  }
  .hm_blg_col_dvdr_cnt {
    margin-top: 10px;
  }
  .hm_blg_col_dvdr {
    margin-bottom: 35px;
  }
  /* end of blog */
  /* start of dark universe exchange */

  .hm_subtle_org {
    font-size: 22px;
  }
  .hm_dls_wht {
    font-size: 22px;
  }
  .hm_due_crd {
    background: url("./Assets/homepage/due_card.svg");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    min-width: 450px;
    width: 450px;
    max-width: 450px;
    min-height: 620px;
    height: 620px;
    max-height: 620px;
    padding: 100px 45px 20px 45px;
  }
  .hm_due_crd_img {
    width: 70px;
  }
  .hm_due_crd_cnt {
    font-size: 20px;
  }
  /* end of dark universe exchange */

  /* start of dark pay  */

  .hm_drkpay_crd_cnt {
    font-size: 21px;
  }

  /* end of dark pay */

  /* start of air drop */

  .ad_data_wht_p,
  .ad-data_orng_p {
    font-size: 20px;
  }
  .ad_form_crd {
    padding: 70px 75px;
  }
  .ad_form_crd h1 {
    font-size: 45px;
  }
  .ad_form_content {
    font-size: 20px;
  }
  .ad_form_crd {
    font-size: 20px;
  }
  .cust_react_slt {
    height: 55px;
  }
  .cust_react_slt .css-1bfvuc2-ValueContainer {
    height: 55px;
  }
  .cust_react_slt .css-1bfvuc2-ValueContainer {
    font-size: 20px;
  }
  .cust_react_slt .css-hjgsbg-IndicatorsContainer {
    height: 55px;
  }
  .ad_wlt_address {
    height: 55px;
  }
  .ad_connect_btn,
  .ad_cont_btn {
    height: 55px;
  }
  .cust_react_slt .css-1nmdiq5-menu {
    margin-top: 16px;
  }
  .ad_faq_row {
    margin-top: 8rem !important;
  }
  .ad_faq_tle {
    font-size: 24px;
  }

  .ad_faq_subtle {
    font-size: 18px;
  }
  .ad_faq_card_one {
    padding: 35px;
    min-height: 400px;
    height: 400px;
  }
  .ad_faq_card_one:hover {
    padding: 35px;
    min-height: 400px;
    height: 400px;
  }
  .ad_faq_card_two {
    padding: 35px;
    min-height: 400px;
    height: 400px;
  }
  .ad_faq_card_two:hover {
    padding: 35px;
    min-height: 400px;
    height: 400px;
  }
  .ad_faq_card_three {
    padding: 35px;
    min-height: 400px;
    height: 400px;
  }
  .ad_faq_card_three:hover {
    padding: 35px;
    min-height: 400px;
    height: 400px;
  }
  .ad_faq_card_four {
    padding: 35px;
    min-height: 400px;
    height: 400px;
  }
  .ad_faq_card_four:hover {
    padding: 35px;
    min-height: 400px;
    height: 400px;
  }
  .ad_faq_card_five {
    padding: 35px;
    min-height: 400px;
    height: 400px;
  }
  .ad_faq_card_five:hover {
    padding: 35px;
    min-height: 400px;
    height: 400px;
  }
  .ad_faq_card_six {
    padding: 35px;
    min-height: 400px;
    height: 400px;
  }
  .ad_faq_card_six:hover {
    padding: 35px;
    min-height: 400px;
    height: 400px;
  }
  .ad_ban_tle {
    font-size: 68px;
  }
  .ad_ban_subtle {
    width: 90%;
    font-size: 28px;
  }
  .ad_faq_ind_one {
    top: -20px;
    left: -21px;
  }
  .ad_faq_ind_one img {
    width: 75px;
  }
  .ad_faq_ind_two {
    bottom: -15px;
    right: -9px;
  }
  .ad_faq_ind_two img {
    width: 75px;
  }
  /* end of air drop */
}

.nrml_inpt_bg{
  background: #232323;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* end of homepage */

@media only screen and (max-width:575px)
{
  .wallet_address_wrap
  {
    word-break: break-all;
    padding: 5px;
    max-width: 90%;
    font-size: 12px;
    text-align: center;
  }
}

@media only screen and (min-width:576px) and (max-width:767px)
{
  .wallet_address_wrap
  {
    word-break: break-all;
    padding: 5px;
    max-width: 90%;
    font-size: 14px;
    text-align: center;
    
  }
}