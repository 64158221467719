@import url('https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,200;9..40,300;9..40,400;9..40,500;9..40,600;9..40,700;9..40,800;9..40,900&display=swap');



:root{
  --whitext: #fff;
}
.h-100vh{
  height: 100vh;
}
.h-100vh h1{
  color: var(--whitext);
}
.darkimg{
  position: absolute;
  left: 50%;
  transform: translate(-50%,-50%);
  margin-top: 0px !important;
}
.btn:focus{
  box-shadow: unset !important;
}

body{
background: url("./Assets/Stars.jpg");
font-family: 'DM Sans', sans-serif;
}
body::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 10px;
	background-color: #fff;
}

body::-webkit-scrollbar
{
	width: 9px;
	background-color: #F5F5F5;
}

body::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #9E6505;
}
.container{
  max-width: 90%;
}
.Homepage{
 overflow: hidden;
}
/* header */
.offcanvas-header .logo{
  width: 170px;
}
.navbar{
  padding-top: 20px;
  padding-bottom: 20px;
}
.logo{
  width: 200px;
}
.themebtn{
  background: url("./Assets/btnback.png") no-repeat;
  background-size: 100% 100%;
  color: #fff;
  padding: 10px 20px
}
.themebtn:hover{
 filter: drop-shadow(2px -2px 14px #9b6204a3);
}
.navbar-light .navbar-nav .nav-link{
  color: #fff;
}
.navbar-nav{
  align-items: center;
}
.navbar-nav a{
  color: #fff !important;
  text-decoration: none;
  margin: 0px -13px;
  background: url("./Assets/normalvector.png") no-repeat;
  background-size: 100% 100%;
  padding: 10px 36px 20px 36px;
  cursor: pointer;
  min-width: 160px;
  text-align: center;
}

a.active{
  /* margin: 0px -24px; */
  background: url("./Assets/activevector.png") no-repeat;
  background-size: 100% 100%;
}
.btn-close{
  opacity: 1 !important;
  filter: invert(1);
}
.footer{
  border-top: 1px solid #FFFFFF33;
}
.footer .row{
  align-items:center;
  justify-content: space-between
}
.media{
  width: 32px;
  height: 32px;
  object-fit: contain;
  aspect-ratio: 3/2;
}
.footer a{
  margin-left: 5px;
}
.footer a:hover{
 filter:drop-shadow(2px 4px 6px #9b6204a3);
}
/* header end*/



/* homepage */
.nft{
  position: relative;
  z-index: 6;
}
.darkjsoncom{
  position: absolute;
  top: 0%;
  right: -5%;
  z-index: -1;
  width: 80%;
  opacity: 0.6;
}
.darkjson1{
    position: absolute;
    top: -5%;
    left: -10%;
    z-index: -1;
}
.darkjson11{
  position: absolute;
  top: 0%;
  left: 0%;
  z-index: -1;
}
.darkjson2{
  position: absolute;
  top: 10%;
  right: 0%;
  z-index: -1;
}
.heading{
  color: var(--whitext);
  font-size: 58px;
}
.heading1{
  color: var(--whitext);
  font-size: 40px;
}
.subheading{
  color: var(--whitext);
  font-size: 16px;
  font-weight: 300;
}
.nftsubhead{
  font-size: 15px !important;
}
.cardborder{
  background: url("./Assets/Features/cardborder.png") no-repeat;
  background-size: 100% 100%;
  padding: 30px 20px;
  min-height: 270px;
  height: 270px;
  max-height: 270px;
}
.contractborder{
  background: url("./Assets/contractborder.png") no-repeat;
  background-size: 100% 100%;
  padding: 30px 20px;
  min-height: 400px;
  height: 400px;
  max-height: 400px;
}
.nftborder{
  background: url("./Assets/nftcard.png") no-repeat;
  background-size: 100% 100%;
  padding: 30px 20px;
  min-height: 410px;
  height: 410px;
  max-height: 410px;
  position: relative;
}
.faarrow{
    position: absolute;
    right: 10%;
    bottom: 6%;
    font-size: 30px;
    fill: #d9d9d9;
    font-weight: 300;
    cursor: pointer;
}
.faarrow:hover{
  fill: #ffcf39;
}
.themebtn:hover{
 color: #fff;
}
.arrowimg {
  position: absolute;
  right: 0px;
  bottom: 0;
  width: 35%;
}
.nftborder h5{
  color: var(--whitext);
}
.community {
  position: relative;
  z-index: 6;
}
.community .card{
  background: url("./Assets/community/card.png") no-repeat;
  background-size: 100% 100%;
  padding: 0px 25px 50px 25px;
  text-align: center;
}
.dark .card{
  background: url("./Assets/darkuniverse/darkvector.png") no-repeat;
  background-size: 100% 100%;
  padding: 0px 25px 50px 25px;
  text-align: center;
  height: 320px;
  min-height: 320px;
}
.list .card{
  background: url("./Assets/homepage/123.png") no-repeat;
  background-size: 100% 100%;
  padding: 0px 25px 50px 25px;
  text-align: center;
  height:440px;
  min-height:440px;
  text-align: left;
}
.darkborder{
    border: 1px solid #865604;
    border-radius: 50%;
    padding: 2px;
}
.commuimg{
  width: 72px;
  height: 72px;
  object-fit: contain;
  margin: auto;
  margin-top: -10%;
}
.featureimg{
  width: 72px;
  height: 72px;
  object-fit: contain;
}
.community .growth .row{
  justify-content: space-between;
}
.community .growth {
  margin: auto;
}
.card h5{
  color: var(--whitext);
}
.titleback{
  background: url("./Assets/titlevector.png") no-repeat;
  background-size: 100% 100%;
  text-align: center;
  padding: 20px 20px 14px 20px;
  color: #fff;
}
.cardborder h5 {
  color: var(--whitext);
  text-transform: uppercase;
}
.contractborder h5 {
  color: var(--whitext);
}
.subtext{
  max-width: 90%;
    margin: auto;
}
.round{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-68%, -50%);
  width: 35%;
  z-index: -1;
}
.round1{
  transform: translate(-50%, -50%) !important;
  width: 39% !important;
}
.tokenomics,.smart{
  position: relative;
  z-index: 6;
}
.tokenomics::after{
  background: #9e6505b5;
  content: "";
  filter: blur(50px);
  height: 550px;
  opacity: .2;
  position: absolute;
  left: -60px;
  top: 90px;
  transform: rotate(178deg);
  width: 300px;
  z-index: -1;
}
.smart::after{
  background: #9e6505b5;
  content: "";
  filter: blur(50px);
  height: 550px;
  opacity: .2;
  position: absolute;
  right: -60px;
  top: 120px;
  transform: rotate(178deg);
  width: 300px;
  z-index: -1;
}
.contractimg{
  max-width: 80%;
  margin: auto;
}
.contractimg{
  margin-top: -20px;
  /* position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
  margin-bottom: 20px; */
}
.leftvector{
  position: absolute;
  max-width: 20%;
  top: 0;
  left: 25%;
}
.rightvector{
  position: absolute;
  top: 0;
  max-width: 20%;
  right: 25%;
}
.svgcontract{
  position: absolute;
  top: 30%;
  right: 18%;
  width: 30%;
  transform: rotate3d(1, 1, 1, 45deg);
}
.dimg {
  transform: rotate3d(1, 1, 1, 45deg);
  position: absolute;
  width: 16%;
  top: 39%;
  right: 25%;
  z-index: 7;
}
.nftjson{
  margin-top: -20%;
}
.nftposition{
  position: relative;
  z-index: 6;
}
.nftposition::after{
  background: #9e6505b5;
  content: "";
  filter: blur(50px);
  height: 420px;
  opacity: .2;
  position: absolute;
  left: 0px;
  bottom: 40px;
  transform: rotate(10deg);
  width: 100%;
  z-index: -1;
}
.darkuniverselottie {
  position: absolute;
  width: 45%;
  left: 0;
  top: 15%;
  z-index: -1;
}
.darkuniverselottie1{
  position: absolute;
  width: 30%;
  right: 0;
  bottom: 0%;
  z-index: -1;
}
.starss{
  position: absolute;
    width: 80%;
    z-index: -2;
}
.navbar-light .navbar-toggler-icon{
  background-image:url("./Assets/menubar1.svg") !important;
}
/* .navbar-toggler{
  background: #7b5411;
} */
.navbar-toggler-icon{
  /* filter: invert(1); */
  width: 38px !important;
  height: 38px !important;
}


@media only screen and (min-width: 1801px) and (max-width: 1930px)   {
  .svgcontract {
    top: 25%;
    right: 38%;
    width: 27%;
  }
  .dimg {
    width: 15%;
    top: 35%;
    right: 44%;
 }
}


@media only screen and (min-width: 1701px) and (max-width: 1800px)   {
  .svgcontract {
   top: 24%;
   right: 36%;
   width: 27%;
 }
 .dimg {
   width: 15%;
   top: 35%;
   right: 42%;
}
}




@media only screen and (min-width: 1651px) and (max-width: 1700px)   {
  .svgcontract {
   top: 27%;
   right: 30%;
   width: 27%;
 }
 .dimg {
   width: 15%;
   top: 36%;
   right: 36%;
}
}


@media only screen and (min-width: 1450px) and (max-width: 1650px)   {
   .svgcontract {
    top: 30%;
    right: 26%;
    width: 27%;
  }
  .dimg {
    width: 15%;
    top: 38%;
    right: 32%;
}
}


@media only screen and (min-width: 1450px) and (max-width: 1930px)   {
  .arrowimg{
    width: 25%;
   }
   .faarrow{
    right: 7%;
   }
}




@media screen and (max-width: 1199px) {
  .section2{
    flex-wrap: wrap-reverse;
  }
  .section2 .growthjson{
    margin-bottom: 12%;
  }
  .round1{
    width: 54%;
  }
  .contractborder{
    min-height:474px;
    height:474px;
    max-height:474px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px)   {
  .media
  {
    width: 25px !important;
    height: 25px !important;
  }
  
  .footer a{
    margin-left: 5px !important;
  }
  .arrowimg{
    width: 29%;
   }
   .logo {
    width: 130px;
}
}

@media screen and (min-width: 991px) {
  .footer .socialmedia{
    text-align: end;
  }
  .footer .subheading{
    text-align: center;
  }
}
@media screen and (max-width: 991px) {
  .navbar-nav{
    align-items: unset;
  }
  .navbar-nav a{
    background-size: unset;
    margin: 10px 0px;
  }
  .navbar-nav a{
    padding: 10px 36px 20px 46px;
    text-align: left;
  }
  .offcanvas-end{
    background: #000;
    box-shadow: 0 3px 10px #825304b0;
    width: 300px !important;
  }
  .footer .row{
   text-align: center;
  }
  .round1{
    width: 72%;
  }
  .contractborder{
    min-height:454px;
    height:454px;
    max-height:454px;
  }
  .leftvector{
    max-width: 35%;
    left: 10%;
  }
  .rightvector{
    max-width: 35%;
    right: 10%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px)   {
  .nftsubhead{
    font-size: 14px !important;
  }
  .arrowimg{
    width: 33%;
   }
   .faarrow{
    bottom: 5%;
    font-size: 28px;
   }
   .list .card{
     min-height: 470px;
    height: 470px;
   }
}

@media screen and (max-width: 767px) {
  .round1{
    width: 46%;
  }
  .contractborder{
    min-height:unset;
    height:unset;
    max-height:unset;
  }
  .leftvector {
    max-width: 35%;
    left: 0%;
    top: 10%;
}
.rightvector {
  max-width: 35%;
  right: 0%;
  top: 10%;
}

}

@media only screen and (min-width: 701px) and (max-width: 766px)   {
  .arrowimg{
    width: 24%;
   }
}

@media only screen and (min-width: 611px) and (max-width: 700px)   {
  .arrowimg{
    width: 24%;
   }
}

@media only screen and (min-width: 576px) and (max-width: 610px)   {
  .arrowimg{
    width: 28%;
   }
}

@media screen and (max-width: 575px) {
  .footer a{
    margin-left: 3px !important;
  }
  .media
  {
    width: 25px !important;
    height: 25px !important;
  }
  

  .tokenomics::after,.smart::after,.nftposition::after{
    background: unset;
  }
  .dark .card{
    height: unset;
    min-height: unset;
  }
  .leftvector,.rightvector{
     display: none;
  }
  .heading{
    font-size: 50px;
  }
  .heading1{
    font-size: 34px;
  }
  .cardborder{
    min-height:unset;
    height:unset;
    max-height:unset;
  }
  .subtext{
    max-width: 100%;
      margin: auto;
  }
  .round1{
    width: 60%;
  }
  .nftborder{
    min-height: 440px;
    height: 440px;
    max-height: 440px;
  }
  .nftsubhead{
    font-size: 15px !important;
  }
  .faarrow {
    right: 10%;
    bottom: 4%;
    font-size: 28px;
}
.navbar .logo{
  width: 180px;
}
}

@media only screen and (min-width: 480px) and (max-width: 575px)   {
  .arrowimg{
   width: 30%;
  }
}

@media screen and (max-width: 374px) {
  .navbar .logo{
    width: 160px;
  }
  .heading{
    font-size: 40px;
  }
  .heading1{
    font-size: 28px;
  }
  .subheading{
    font-size: 14px;
  }
  .nftborder{
    min-height: 400px;
    height: 400px;
    max-height: 400px;
  }
  .nftsubhead{
    font-size: 13px !important;
  }
  .nftborder{
    padding: 30px 14px;
  }
  .faarrow {
    right: 10%;
    bottom: 3%;
    font-size: 26px;
}
}



@media(min-width:1200px){
  .list .card{
    min-height: 350px;
    height: 350px;
  }
}
@media(min-width:1400px){
  .list .card{
    min-height: 420px;
    height: 420px;
  }
}


@media(min-width:1920px){
  .hm_drk_pay_cnt{
    font-size: 20px;
  }
  .list .card{
    min-height: 490px;
    height: 490px;
  }
  .roundss{
    left: 45%;
  }
  .themebtn{
    font-size: 22px;
    height: 60px;
  }
}
.themebtn .text-decoration-none
{
  color:#fff !important;
}

.wallet_img:hover {
  filter: drop-shadow(2px 4px 6px #fff);
}
.text_yellow
{
  color:#bb7602;
}
.btn_secti_right
{
  margin-top: 40px;
  text-align: center;
}
.btn_secti_right .btn
{
  padding-left: 30px !important;
  padding-right: 30px !important;
}
.mr_back
{
  margin-right: 20px !important;
}
.form_yell
{
  background: transparent;
    border: none;
    outline: none;
    height: 100%;
    color: #fff;
    color: var(--gray_clr);
    font-weight: 600;
    width: 100% !important;
    border: 2px solid var(--orange_clr);
    border-radius: 5px;
    height: 50px;
}

@media only screen and (max-width:575px)
{
  .btn_secti_right .btn
  {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
}

.socai_foo a
{
  cursor: pointer;
}
.socai_foo a:hover

{
  filter: drop-shadow(2px 4px 6px #9b6204a3);
}
.img_partner
{
  max-width: 250px;
  margin: 0 auto;
  position: relative;
}
.img_partner:hover
{
/* margin-top: -20px; */
transform: scale(1.2);
transition: all 0.5s ease-in-out;
}